import { useRouter } from 'next/router';

import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import NextLink from 'src/components/atoms/link';
import { useBreakpointV2 } from 'src/helpers/use';

import Button from '@components/atoms/button';
import FontAwesomeIcon from '@components/atoms/fontAwesomeIcon';
import CarouselArrowButton from '@components/molecules/learningPathSection/carouselArrowButton';
import LearningPathCard from '@components/molecules/learningPathSection/learningPathCard';
import { LearningPathSectionStyle } from '@components/organisms/sections/learningPathSection/style';
import useLearningPathSection from '@hooks/learningPath/useLearningPathSection';
import { ISection } from '@interfaces/course';

import { CarouselArrowDirection } from '@common/enum/learningPath';
import { LEARNING_PATH_BG } from '@common/constant/iconConstant';

type LearningPathSectionProps = {
  section: ISection;
};

const LearningPathSection = ({ section }: LearningPathSectionProps) => {
  const { t } = useTranslation('common');
  const { learningPathSectionItemList } = useLearningPathSection();
  const router = useRouter();
  const theme = useTheme();
  const responsive: ResponsiveType = {
    largeDesktop: {
      breakpoint: { max: 6000, min: 2000 },
      items: 2,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 1999, min: 1200 },
      items: 2,
      paritialVisibilityGutter: 40,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 1199, min: 768 },
      items: 1.5,
      slidesToSlide: 1,
    },
    smallTablet: {
      breakpoint: { max: 765, min: 500 },
      items: 1.5,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 499, min: 0 },
      items: 1.1,
      slidesToSlide: 1,
    },
  };

  const { isDesktop, isMobile } = useBreakpointV2();

  if (learningPathSectionItemList.isLoading || !learningPathSectionItemList.data) {
    return null;
  }

  return (
    <LearningPathSectionStyle>
      <Stack
        gap={{
          xs: 2,
          md: 3,
        }}
        className="container"
        width="100%"
      >
        <Box display="flex" justifyContent="space-between" pl={1} flexWrap="wrap" alignItems="center">
          <Typography variant={isMobile ? 'h3' : 'h1'} color={theme.palette.primary.contrastText}>
            {section.name}
          </Typography>
          <NextLink href="learning-path">
            <Button
              variant="text"
              sx={{
                color: theme.palette.background.paper,
                gap: 1,
              }}
            >
              {t('home.section.see_all')} <FontAwesomeIcon icon="fa-regular fa-chevron-right" />
            </Button>
          </NextLink>
        </Box>
        <Carousel
          responsive={responsive}
          partialVisible={!isDesktop}
          draggable={!isDesktop}
          swipeable={!isDesktop}
          containerClass="carousel-container"
          itemClass={`carousel-item ${learningPathSectionItemList.data?.length <= 2 && 'width'}`}
          ssr
          deviceType={isDesktop ? 'desktop' : 'mobile'}
          arrows={isDesktop}
          customRightArrow={<CarouselArrowButton direction={CarouselArrowDirection.RIGHT} />}
          customLeftArrow={<CarouselArrowButton direction={CarouselArrowDirection.LEFT} />}
        >
          {learningPathSectionItemList.data?.map((learningPath) => (
            <LearningPathCard
              key={learningPath.id}
              learningPathname={learningPath.name}
              imageSrc={learningPath.cardImage}
              onClick={() => router.push(`/learning-path/${learningPath.id}`)}
            />
          ))}
        </Carousel>
      </Stack>
    </LearningPathSectionStyle>
  );
};

export default LearningPathSection;
