import { Typography } from '@mui/material';
import styled from 'styled-components';

export const EllipsisTypography = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
`;
