import { Box, Stack } from '@mui/material';

import { EllipsisTypography } from './style';

import { getAssetUrl } from '@helpers/utils';

type LearningPathCardProps = {
  learningPathname: string;
  imageSrc: string;
  onClick: () => void;
};
const LearningPathCard = ({ learningPathname, imageSrc, onClick }: LearningPathCardProps) => {
  return (
    <Box
      display="flex"
      height="100%"
      p={2}
      gap={{
        xs: 2,
        md: 4,
      }}
      borderRadius={4}
      bgcolor="background.paper"
      width="100%"
      onClick={onClick}
      sx={{
        cursor: 'pointer',
      }}
      flexDirection={{
        xs: 'column',
        md: 'row',
      }}
    >
      <Box
        minWidth={{
          xs: '100%',
          md: 280,
        }}
        height={170}
        borderRadius={2}
        overflow="hidden"
      >
        <Box
          component="img"
          src={imageSrc ?? getAssetUrl('thumbnail-course-card.jpg')}
          width="100%"
          height="100%"
          sx={{
            objectFit: 'cover',
          }}
        />
      </Box>
      <Box overflow="hidden" height={{ xs: 'calc(100% - 170px);', md: '100%' }}>
        <Stack height="100%" justifyContent="space-between">
          <EllipsisTypography variant="h3" height="fit-content">
            {learningPathname}
          </EllipsisTypography>
        </Stack>
      </Box>
    </Box>
  );
};

export default LearningPathCard;
