export const MY_LEARNING_PATH_EMPTY = '/static/icons/my-learning-path-empty.svg';
export const LEARNING_PATH_CERTIFICATE = '/static/images/learningPath/learning-path-certificate.png';
export const LEARNING_PATH_HEADER_COMPONENT_1 = '/static/images/learningPath/learning-path-header-component-1.png';
export const LEARNING_PATH_HEADER_COMPONENT_2 = '/static/images/learningPath/learning-path-header-component-2.png';
export const LEARNING_PATH_HEADER_COMPONENT_3 = '/static/images/learningPath/learning-path-header-component-3.png';
export const LEARNING_PATH_HEADER_COMPONENT_4 = '/static/images/learningPath/learning-path-header-component-4.png';
export const CERTIFICATE_EMPTY = '/static/images/certificate/empty-certificate.svg';
export const CERTIFICATE_EMPTY_DIRECTORY = '/static/images/certificate/empty-directory.svg';
export const LEARNING_PATH_BG = '/static/images/common/learning-path-bg.png';
export const SELECT_CERTIFICATE = '/static/icons/select-certificate.svg';
export const SELECT_COURSE = '/static/icons/select-course.svg';
export const COURSE_CRITERIA = '/static/icons/course-criteria.svg';
export const RECEIVE_CERTIFICATE = '/static/icons/receive-certificate.svg';
export const LEARNING_PATH_HOME_BACKGROUND = '/static/images/learningPath/learning-path-home-bg.png';
